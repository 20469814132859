import { Button as BaseButton, ButtonProps as BaseButtonProps } from "@chakra-ui/react";
import { Flex, Text } from "@workshop/ui";
import React, { FC } from "react";

export type ButtonProps = BaseButtonProps & {
  icon?: string;
  iconColor?: string;
  secondary?: boolean;
  label: string;
};

export type ButtonRef =
  | string
  | ((instance: HTMLButtonElement | null) => void)
  | React.RefObject<HTMLButtonElement>
  | null
  | undefined;

export type ButtonPropsWithRef = ButtonProps & {
  ref?: ButtonRef;
};

const Button: FC<ButtonPropsWithRef> = ({ label, icon, iconColor, secondary = false, isDisabled, ...rest }) => (
  <BaseButton
    _focus={{ boxShadow: "none" }}
    cursor="pointer"
    backgroundColor="black"
    borderRadius="sm"
    color="black"
    isDisabled={isDisabled}
    {...rest}
  >
    <Flex justifyContent="center" alignItems="center">
      <Text color={isDisabled ? "gray.400" : "white"} fontWeight="medium">
        {label}
      </Text>
    </Flex>
  </BaseButton>
);

export default Button;
