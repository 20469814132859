import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link as ExternalLink,
  Text,
} from '@workshop/ui';
import navRoutes from 'navigation/Routes';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAuthError } from 'redux/selectors/auth';
import { LoginData } from 'types';
import isEmail from 'validator/lib/isEmail';

interface FormData {
  email: string;
  password: string;
}

interface LoginFormProps {
  onSubmit: (data: LoginData) => Promise<void>;
  isLoading?: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit, isLoading }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },

    watch,
  } = useForm<FormData>({
    defaultValues: { email: '', password: '' },
  });

  const onSubmitData = handleSubmit(async ({ email, password }) => {
    setIsSubmitting(true);
    await onSubmit({
      email,
      password,
    });
    setIsSubmitting(false);
  });

  const { errorMessage } = useAuthError();

  const touchedFields = watch(['email', 'password']).filter(a => a.length > 1);

  return (
    <Flex flex={1} width="100%" maxW={500}>
      <Flex flexDirection="column" flex={1}>
        <Flex flexDirection="column" mb="4">
          <FormControl isInvalid={Boolean(errors.email)}>
            <FormLabel fontSize="sm" style={{ color: '#3C4257' }}>
              Email
            </FormLabel>
            <Input
              {...register('email', {
                required: true,
                validate: value => isEmail(value),
              })}
              id="email"
              name="email"
              type="email"
              placeholder="Email"
            />
            <FormErrorMessage color="statusError">
              Please enter a valid email address.
            </FormErrorMessage>
          </FormControl>
        </Flex>

        <Flex flexDirection="column" mb="4">
          <FormControl isInvalid={Boolean(errors.password)}>
            <FormLabel fontSize="sm" style={{ color: '#3C4257' }}>
              Password
            </FormLabel>
            <Input
              {...register('password', {
                required: {
                  value: true,
                  message: 'Please enter your password.',
                },
                minLength: {
                  value: 6,
                  message: 'Your password must be at least 6 characters',
                },
              })}
              id="password"
              name="password"
              type="password"
              placeholder="Password"
              mt={2}
            />
            <FormErrorMessage color="statusError">{errors.password?.message}</FormErrorMessage>
          </FormControl>
        </Flex>
        <FormControl isInvalid={Boolean(errorMessage)}>
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControl>
        <Button
          variant="solid"
          colorScheme="blue"
          type="submit"
          textTransform="initial"
          mt={2}
          fontSize="md"
          isDisabled={isLoading || isSubmitting || Object.values(touchedFields).length < 2}
          isLoading={isLoading || isSubmitting}
          onClick={onSubmitData}
          label="Log In"
        />
        <Flex mt="4" py={4} width="100%" flexDir="column">
          <Link to={navRoutes.public.forgotPassword.path()}>
            <Text as="span" fontWeight="bold">
              Forgot your password?
            </Text>
          </Link>
          <Text flex={1} my="8" fontSize="sm">
            By continuing, you are indicating that you accept our{' '}
            <ExternalLink href="https://sortedfood.com/website-terms/" target="_blank">
              <Text as="span" fontWeight="bold" color="secondary">
                Terms of Service
              </Text>
            </ExternalLink>{' '}
            and{' '}
            <ExternalLink href="https://sortedfood.com/privacy-policy/" target="_blank">
              <Text as="span" fontWeight="bold" color="secondary">
                Privacy Policy.
              </Text>
            </ExternalLink>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LoginForm;
