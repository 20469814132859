/* eslint import/no-extraneous-dependencies: "off" */
import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

import AccessibilityNew from '@material-symbols/svg-600/rounded/accessibility_new.svg';
import Add from '@material-symbols/svg-600/rounded/add.svg';
import AddCircle from '@material-symbols/svg-600/rounded/add_circle.svg';
import Alarm from '@material-symbols/svg-600/rounded/alarm.svg';
import ArrowCircleRight from '@material-symbols/svg-600/rounded/arrow_circle_right.svg';
import ArrowCircleUp from '@material-symbols/svg-600/rounded/arrow_circle_up.svg';
import BarChart from '@material-symbols/svg-600/rounded/bar_chart.svg';
import CalendarAddOn from '@material-symbols/svg-600/rounded/calendar_add_on.svg';
import CalendarMonth from '@material-symbols/svg-600/rounded/calendar_month.svg';
import ChevronRight from '@material-symbols/svg-600/rounded/chevron_right.svg';
import ChevronLeft from '@material-symbols/svg-600/rounded/chevron_left.svg';
import Cooking from '@material-symbols/svg-600/rounded/cooking.svg';
import Cycle from '@material-symbols/svg-600/rounded/cycle.svg';
import DoNotDisturb from '@material-symbols/svg-600/rounded/do_not_disturb_off.svg';
import Download from '@material-symbols/svg-600/rounded/download.svg';
import Eco from '@material-symbols/svg-600/rounded/eco-fill.svg';
import Error from '@material-symbols/svg-600/rounded/error.svg';
import FolderSpecial from '@material-symbols/svg-600/rounded/folder_special.svg';
import FoodBank from '@material-symbols/svg-600/rounded/food_bank.svg';
import Group from '@material-symbols/svg-600/rounded/group.svg';
import Home from '@material-symbols/svg-600/rounded/home.svg';
import HotelClass from '@material-symbols/svg-600/rounded/hotel_class.svg';
import KeyBoardDoubleArrowLeft from '@material-symbols/svg-600/rounded/keyboard_double_arrow_left.svg';
import KeyboardDoubleArrowRight from '@material-symbols/svg-600/rounded/keyboard_double_arrow_right.svg';
import Kitchen from '@material-symbols/svg-600/rounded/kitchen.svg';
import Link from '@material-symbols/svg-600/rounded/link.svg';
import MoreHoriz from '@material-symbols/svg-600/rounded/more_horiz.svg';
import Notifications from '@material-symbols/svg-600/rounded/notifications.svg';
import Nutrition from '@material-symbols/svg-600/rounded/nutrition.svg';
import PhotoCamera from '@material-symbols/svg-600/rounded/photo_camera.svg';
import Restaurant from '@material-symbols/svg-600/rounded/restaurant.svg';
import Save from '@material-symbols/svg-600/rounded/save.svg';
import Search from '@material-symbols/svg-600/rounded/search.svg';
import Settings from '@material-symbols/svg-600/rounded/settings.svg';
import Share from '@material-symbols/svg-600/rounded/share.svg';
import ShoppingCart from '@material-symbols/svg-600/rounded/shopping_cart.svg';
import Shuffle from '@material-symbols/svg-600/rounded/shuffle.svg';
import SMS from '@material-symbols/svg-600/rounded/sms.svg';
import SoupKitchen from '@material-symbols/svg-600/rounded/soup_kitchen.svg';
import Summarize from '@material-symbols/svg-600/rounded/summarize.svg';
import ThumbDown from '@material-symbols/svg-600/rounded/thumb_down.svg';
import ThumbsUp from '@material-symbols/svg-600/rounded/thumb_up.svg';
import TipsAndUpdates from '@material-symbols/svg-600/rounded/tips_and_updates.svg';
import Visibility from '@material-symbols/svg-600/rounded/visibility.svg';
import VisibilityOff from '@material-symbols/svg-600/rounded/visibility_off.svg';
import YTActivityFill from '@material-symbols/svg-600/rounded/youtube_activity-fill.svg';

type IconSizes = { size?: 'sm' | 'md' | 'lg' | 'xl' | number };

/** Icon component (Material) */
export const iconSizes = {
  default: 10,
  sm: 8,
  md: 16,
  lg: 24,
  xl: 40,
} as const;

export const CustomIconMap = {
  // Icons from @material-symbols/svg-600
  'accessibility-new': AccessibilityNew,
  'add-circle': AddCircle,
  'arrow-circle-right': ArrowCircleRight,
  'arrow-circle-up': ArrowCircleUp,
  'bar-chart': BarChart,
  'calendar-add-on': CalendarAddOn,
  'calendar-month': CalendarMonth,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'do-not-disturb': DoNotDisturb,
  'folder-special': FolderSpecial,
  'food-bank': FoodBank,
  'hotel-class': HotelClass,
  'keyboard-double-arrow-left': KeyBoardDoubleArrowLeft,
  'keyboard-double-arrow-right': KeyboardDoubleArrowRight,
  'more-horiz': MoreHoriz,
  'photo-camera': PhotoCamera,
  'shopping-cart': ShoppingCart,
  'soup-kitchen': SoupKitchen,
  'thumbs-down': ThumbDown,
  'thumbs-up': ThumbsUp,
  'tips-and-updates': TipsAndUpdates,
  'visibility-off': VisibilityOff,
  'youtube-activity-fill': YTActivityFill,
  add: Add,
  alarm: Alarm,
  cooking: Cooking,
  cycle: Cycle,
  download: Download,
  eco: Eco,
  error: Error,
  group: Group,
  home: Home,
  kitchen: Kitchen,
  link: Link,
  notifications: Notifications,
  nutrition: Nutrition,
  restaurant: Restaurant,
  save: Save,
  search: Search,
  settings: Settings,
  share: Share,
  shuffle: Shuffle,
  sms: SMS,
  summarize: Summarize,
  visibility: Visibility,
} as const;

/**
 * Loop through all of the Icons from react-icons/md and
 * wrap them in our Chakra Box. With this we can use our
 * theme values as inputs/props to the icons for ayout and
 * styling
 */

const CustomIcon: React.FC<CustomIconProps> = ({ name, size, src, alt, ...props }) => {
  let iconSize: number = iconSizes.default;
  if (size) {
    iconSize = typeof size === 'number' ? size : iconSizes[size];
  }

  return (
    // @ts-ignore
    <img
      alt={name || alt}
      {...props}
      src={src || (name ? CustomIconMap[name] : undefined)}
      width={iconSize}
      height={iconSize}
    />
  );
};

export type CustomIconProps = BoxProps &
  IconSizes & {
    alt?: string;
    src?: string;
    name?: keyof typeof CustomIconMap;
  };

const Icon: React.FC<CustomIconProps> = ({ name, size, ...props }) => {
  return <Box as={CustomIcon} {...props} name={name} size={size} />;
};

export default Icon;
