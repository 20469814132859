import {
  CustomIcon,
  CustomIconProps,
  Flex,
  FlexProps,
  iconSizes,
  Text,
  Skeleton,
} from '@workshop/ui';
import React, { FC } from 'react';

export type TagProps = FlexProps & {
  image?: string;
  isActive?: boolean;
  icon?: CustomIconProps | JSX.Element;
  label: string;
  priority?: 'low' | 'medium' | 'high';
  activeBackgroundColor?: FlexProps['backgroundColor'];
  inActiveBackgroundColor?: FlexProps['backgroundColor'];
  iconPosition?: 'left' | 'right';
  iconSize?: CustomIconProps['size'];
  isLoading?: boolean;
};

const Tag: FC<TagProps> = ({
  image,
  label,
  icon = { name: 'add' },
  isActive,
  activeBackgroundColor = 'errorBackground',
  inActiveBackgroundColor = 'meatBackground',
  iconPosition = 'right',
  iconSize = 'lg',
  isLoading = false,
  onClick,
  ...rest
}) => {
  const mappedIconSize = typeof iconSize === 'number' ? iconSize : iconSizes[iconSize];

  if (isLoading) {
    return (
      <Skeleton isLoaded={false} mr="1" borderRadius="xl">
        <Flex
          alignItems="center"
          borderRadius="xl"
          height="26px"
          width="50px"
          backgroundColor={isActive ? activeBackgroundColor : inActiveBackgroundColor}
          flexDirection="row"
          alignSelf="center"
          disabled={!onClick}
          overflow="hidden"
          {...rest}
          p="0"
          px="0"
          py="0"
        ></Flex>
      </Skeleton>
    );
  }

  return (
    <Flex
      onClick={onClick}
      alignItems="center"
      borderRadius="xl"
      backgroundColor={isActive ? activeBackgroundColor : inActiveBackgroundColor}
      py="1"
      px="2"
      flexDirection="row"
      alignSelf="center"
      disabled={!onClick}
      {...rest}
    >
      {iconPosition === 'left' &&
        (React.isValidElement(icon) ? (
          <Flex
            width={mappedIconSize}
            height={mappedIconSize}
            justifyContent="center"
            alignItems="center"
          >
            {icon}
          </Flex>
        ) : (
          <CustomIcon {...(icon as CustomIconProps)} size={mappedIconSize} />
        ))}
      {label && (
        <Text fontFamily="Greed" ml="1" alignSelf="center" fontSize={12}>
          {label}
        </Text>
      )}
      {iconPosition === 'right' &&
        (React.isValidElement(icon) ? (
          <Flex
            width={mappedIconSize}
            height={mappedIconSize}
            justifyContent="center"
            alignItems="center"
          >
            {icon}
          </Flex>
        ) : (
          <CustomIcon {...(icon as CustomIconProps)} size={mappedIconSize} />
        ))}
    </Flex>
  );
};

export default Tag;
