import { decamelizeKeys } from 'humps';

export interface URLParamObject {
  [key: string]: string | string[] | number | number[] | boolean;
}
/**
 * Helper for building URL parameter strings from a dictionary of params.
 *
 * Specifically, this helper exists to ensure we adhere to a common format for
 * array parameters.
 *
 * Examples:
 * Single string:               { query: 'query string' } => 'query=query+string'
 * Multiple single strings:     { query: 'query string', limit: 3 } => 'query=query+string&limit=3'
 * Single array of strings:     { filters: ['V', 'VE', 'DF'] } => 'filters[]=V&filters[]=VE&filters[]=DF'
 * Multiple arrays of strings:  { filters: ['V', 'VE', 'DF'], tags: ['tag1', 'tag2'] } => 'filters[]=V&filters[]=VE&filters[]=DF&tags[]=tag1&tags[]=tag2'
 * Mixed:                       { query: 'query string', limit: 3, filters: ['V', 'VE', 'DF'], tags: ['tag1', 'tag2'] } => 'query=query+string&limit=3&filters[]=V&filters[]=VE&filters[]=DF&tags[]=tag1&tags[]=tag2'
 *
 * @param URLParamObject
 * @returns string
 */
export const buildParams = (data: URLParamObject | undefined) => {
  if (!data) return '';

  const params = new URLSearchParams();

  Object.entries(decamelizeKeys<URLParamObject>(data)).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(v => params.append(`${key}[]`, v.toString()));
    } else {
      params.append(key, value.toString());
    }
  });

  return params.toString().replace(/%5B/gi, '[').replace(/%5D/gi, ']').replace(/%2C/gi, ',');
};
