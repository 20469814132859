import { createSlice } from '@reduxjs/toolkit';
import { camelizeKeys } from 'humps';

import { API, createAsyncThunk } from 'utils';

import { Recipe, RecipeResponse } from 'types';
import { fetchPack } from './pack';

export type RecipeState = Record<string, Recipe>;

export const initialState: RecipeState = {};

export const fetchRecipe = createAsyncThunk<RecipeResponse, { uid: string }>(
  'recipe/fetch',
  async (api, args) => {
    const res = await api.get(API.urls.recipeSummary(args.uid));
    return res.data;
  },
);

export const recipesSlice = createSlice({
  name: 'recipes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchRecipe.fulfilled, (state, action) => {
        return {
          ...state,
          [action.payload.uid]: camelizeKeys(action.payload) as Recipe,
        };
      })
      .addCase(fetchPack.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload.recipes.reduce((acc, recipe) => {
            acc[recipe.uid] = camelizeKeys(recipe) as Recipe;
            return acc;
          }, {} as RecipeState),
        };
      });
  },
});

export default recipesSlice.reducer;
