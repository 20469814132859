import { combineReducers } from 'redux';

import { GlobalState } from 'types';
import { recipesSlice } from './recipe';
import { packsSlice } from './pack';
import { authReducer } from './auth';
import { uiReducer } from './ui';
import { userDetailReducer } from './user';

const appReducer = combineReducers<GlobalState>({
  auth: authReducer,
  ui: uiReducer,
  user: userDetailReducer,
  recipe: recipesSlice.reducer,
  pack: packsSlice.reducer,
});

export default appReducer;
