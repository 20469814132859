import palette from './palette';

const standardColours = {
  black: '#000',
  white: '#FFF',
  dark: '#000',
  darkGrey: palette.grey.dark,
  light: palette.grey.light,
  lightGrey: palette.grey.light,
  veryLightGrey: palette.grey.veryLight,
  medGrey: palette.grey.medium,
  primary: palette.yellow.primary,
  // DELETE THIS 👇
  primaryLight: palette.yellow.light,
  secondary: palette.green.tertiary,
  veryDark: palette.grey.veryDark,
  veryLight: palette.grey.veryLight,
  tertiary: palette.pink.primary,
};

const textColours = {
  defaultText: palette.black,
  defaultTextReverse: palette.white,
  lightText: palette.grey.medium,
  secondaryLink: palette.brown.primary,
};

const backgroundColours = {
  defaultBg: palette.white,
  defaultBgOffset: palette.grey.veryLight,
  defaultBgReverse: palette.grey.veryDark,
  warmBgReverse: palette.grey.dark,
  secondaryBackground: palette.cream.primary,
  offsetBg: palette.grey.veryLight,
  tagBg: '#F8F8F8',
  fullRecipeBg: palette.brown.primary,
  prepScreenPink: palette.pink.primary,
  storyMeatBg: palette.pink.primary,
  lightPink: palette.pink.light,
  veryLightPink: palette.pink.veryLight,
  veryLightGreen: palette.green.veryLight,
  veryLightBackgroundGreen: palette.green.veryLighBackground,
  secondaryLightBlue: palette.blue.secondaryLight,
  secondaryBlue: palette.blue.primary,
};

const intentColours = {
  successForeground: palette.green.primary,
  successBackground: palette.green.light,
  warningBackground: palette.orange.light,
  errorForeground: palette.black,
  errorBackground: palette.red.primary,
};

const navigationColours = {
  tabNavBtn: palette.grey.dark,
  closeNavBtn: palette.yellow.primary,
};

const homeScreenColours = {
  homePrimaryTitleColour: palette.grey.veryDark,
  homeSecondaryTitleColour: palette.grey.dark,
};

const buttonColours = {
  buttonColour: palette.yellow.primary,
  buttonDarkBg: palette.black,
  buttonLightBg: palette.grey.veryLight,
  disbledBtnColour: palette.grey.medium,
  recipeBackBtnBg: palette.grey.medium,
  recipePlayBtnBg: palette.grey.dark,
};

const gradientColours = {
  buttonGradientStart: palette.yellow.primary,
};

const transparencyColours = {
  transparent: palette.whiteTransparent,
  opacityDark5: 'rgba(0,0,0,0.05)',
  opacityDark15: 'rgba(0,0,0,0.15)',
  opacityDark20: 'rgba(0,0,0,0.2)',
  opacityDark40: 'rgba(0,0,0,0.4)',
  opacityDark50: 'rgba(0,0,0,0.5)',
  opacityDark80: 'rgba(0,0,0,0.8)',
  opacityDark90: 'rgba(0,0,0,0.9)',
  opacitylight10: 'rgba(255,255,255,0.1)',
  opacitylight20: 'rgba(255,255,255,0.2)',
  opacitylight40: 'rgba(255,255,255,0.4)',
  opacityLight60: 'rgba(255,255,255,0.6)',
  opacitylight80: 'rgba(255,255,255,0.8)',
  opacitylight90: 'rgba(255,255,255,0.9)',
};

const statusColours = {
  statusPending: palette.yellow.primary,
  statusConfirmed: palette.green.primary,
  statusCancelled: palette.red.primary,
  statusError: palette.red.primary,
};

const inputColours = {
  searchInputBorder: palette.grey.light,
  inputPlaceholder: palette.grey.medium,
};

const addOpacity = (colorHex: string, opacity: number) => `${colorHex}${opacity.toString(16)}`;

/**
 * The keys for dietaryTypes colours are mapped to the values
 * from the enum DietaryType (see src/types/data.ts)
 */
const dietaryTypes = {
  // MEAT
  meatIcon: palette.pink.primary,
  meatBackground: palette.pink.primaryLight,
  meatBackgroundOpacity: addOpacity(palette.pink.primaryLight, 150),
  // MEAT + FISH
  meatFishIcon: palette.purple.light,
  meatFishBackground: palette.purple.veryLight,
  meatFishBackgroundOpacity: addOpacity(palette.purple.veryLight, 150),
  // FISH
  fishIcon: palette.blue.primary,
  fishBackground: palette.blue.secondaryLight,
  fishBackgroundOpacity: addOpacity(palette.blue.secondaryLight, 150),
  // VEGGIE
  vegIcon: palette.green.veryLight,
  vegBackground: palette.green.veryLighBackground,
  vegBackgroundOpacity: addOpacity(palette.green.veryLighBackground, 150),
  // VEGAN
  veganIcon: palette.green.apple,
  veganBackground: palette.green.appleLight,
  veganBackgroundOpacity: addOpacity(palette.green.appleLight, 150),
} as const;

const colours = {
  ...backgroundColours,
  ...buttonColours,
  ...gradientColours,
  ...homeScreenColours,
  ...inputColours,
  ...intentColours,
  ...navigationColours,
  ...standardColours,
  ...statusColours,
  ...textColours,
  ...transparencyColours,
  ...dietaryTypes,
  yellow: {
    500: palette.yellow.primary,
  },
} as const;

export default colours;
