import { Pack, PackSummary } from 'types';

const defaultPortionSize = 2;

export const getPackPortionSizes = (pack: Pack | PackSummary | null | undefined) => {
  // Without the pack we fall back to our safe default
  if (!pack) return [defaultPortionSize];

  // Primary portion only means only cooking for 2
  if (pack.primaryPortionOnly) return [defaultPortionSize];

  return pack.portionSize.split(',').map(val => parseInt(val));
};

export const getPortionSize = (pack: Pack | PackSummary | null | undefined) => {
  // Without the pack we fall back to our safe default
  if (!pack) return defaultPortionSize;

  // Extract the possible portion sizes from the pack
  const packPortionSizes = getPackPortionSizes(pack);

  // As a backup - our default portion size is 2, so every single pack should
  // include this, however, we check to be certain!
  if (packPortionSizes.includes(defaultPortionSize)) {
    return defaultPortionSize;
  }

  // Failing this, we're left with having to return a valid portion size
  // option from the pack itself
  return packPortionSizes[0];
};
