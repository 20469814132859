import React, { memo } from 'react';

import memoize from 'lodash/memoize';

import { getDietaryIcon, Flex, FlexProps } from '@workshop/ui';

import Tag, { TagProps } from './Tag';

import { DietaryTag } from 'types';

const baseGenerateTags = (args: {
  ingredientsCount?: number;
  numPeople?: number;
  cookingTime?: string;
  dietaryTag?: DietaryTag;
  tagProps?: Partial<TagProps>;
  showDietaryLabel?: boolean;
  isYoutube?: boolean;
  isLoading?: boolean;
}): TagCloudProps['tags'] => {
  const {
    ingredientsCount,
    numPeople,
    cookingTime,
    dietaryTag,
    tagProps = {},
    showDietaryLabel = true,
    isYoutube = false,
    isLoading = false,
  } = args;
  const dietaryIcon = dietaryTag ? getDietaryIcon(dietaryTag) : undefined;

  let tags: TagCloudProps['tags'] = [];

  if (isLoading || isYoutube) {
    tags.push({
      key: 'youtube_tag',
      tag: {
        label: showDietaryLabel ? 'YouTube' : '',
        icon: { name: 'youtube-activity-fill' },
      },
    });
  }

  if (isLoading || dietaryIcon) {
    tags.push({
      key: 'dietary_tag',
      tag: {
        label: showDietaryLabel ? dietaryIcon?.name || '' : '',
        icon: dietaryIcon?.component,
      },
    });
  }

  if (isLoading || cookingTime) {
    tags.push({
      key: 'cooking_time_tag',
      tag: {
        label: cookingTime || '',
        icon: { name: 'alarm' },
      },
    });
  }

  if (ingredientsCount) {
    tags.push({
      key: 'ingredients_tag',
      tag: {
        label: ingredientsCount.toString(),
        icon: { name: 'nutrition' },
      },
    });
  }

  if (numPeople) {
    tags.push({
      key: 'num_people_tag',
      tag: {
        label: numPeople.toString(),
        icon: { name: 'accessibility-new' },
      },
    });
  }

  const tagsBackgroundColor = isYoutube ? 'youtubeIcon' : dietaryIcon?.iconColor;
  tags = tags.map(t => ({
    ...t,
    tag: {
      ...t.tag,
      backgroundColor: tagsBackgroundColor,
      iconPosition: 'left',
      iconSize: 'md',
      isLoading,
      ...tagProps,
    },
  }));

  return tags;
};

export const generateTags = memoize(baseGenerateTags, (...args) => JSON.stringify(args));

export interface TagCloudProps extends FlexProps {
  tags: { key: React.Key; tag: TagProps }[];
}

const TagCloud: React.FC<TagCloudProps> = memo(({ tags, ...rest }) => (
  <Flex flexDirection="row" flexWrap="wrap" justifyContent="center" alignItems="center" {...rest}>
    {tags.map(({ key, tag }) => (
      <Tag key={key} m="xs" {...tag} />
    ))}
  </Flex>
));

export default TagCloud;
