const imgArrow1 = require('../../../assets/arrows/arrow_1.png');
const imgArrow10 = require('../../../assets/arrows/arrow_10.png');
const imgArrow11 = require('../../../assets/arrows/arrow_11.png');
const imgArrow12 = require('../../../assets/arrows/arrow_12.png');
const imgArrow13 = require('../../../assets/arrows/arrow_13.png');
const imgArrow14 = require('../../../assets/arrows/arrow_14.png');
const imgArrow15 = require('../../../assets/arrows/arrow_15.png');
const imgArrow16 = require('../../../assets/arrows/arrow_16.png');
const imgArrow17 = require('../../../assets/arrows/arrow_17.png');
const imgArrow18 = require('../../../assets/arrows/arrow_18@3x.png');
const imgArrow19 = require('../../../assets/arrows/arrow_19.png');
const imgArrow2 = require('../../../assets/arrows/arrow_2.png');
const imgArrow20 = require('../../../assets/arrows/arrow_20@3x.png');
const imgArrow3 = require('../../../assets/arrows/arrow_3.png');
const imgArrow4 = require('../../../assets/arrows/arrow_4.png');
const imgArrow5 = require('../../../assets/arrows/arrow_5.png');
const imgArrow6 = require('../../../assets/arrows/arrow_6.png');
const imgArrow7 = require('../../../assets/arrows/arrow_7.png');
const imgArrow8 = require('../../../assets/arrows/arrow_8.png');
const imgArrow9 = require('../../../assets/arrows/arrow_9.png');
const imgScribble1 = require('../../../assets/scribbles/scribble_1.png');
const imgScribble10 = require('../../../assets/scribbles/scribble_10.png');
const imgScribble11 = require('../../../assets/scribbles/scribble_11.png');
const imgScribble12 = require('../../../assets/scribbles/scribble_12.png');
const imgScribble13 = require('../../../assets/scribbles/scribble_13@3x.png');
const imgScribble14 = require('../../../assets/scribbles/scribble_14.png');
const imgScribble16 = require('../../../assets/scribbles/scribble_16.png');
const imgScribble17 = require('../../../assets/scribbles/scribble_17.png');
const imgScribble18 = require('../../../assets/scribbles/scribble_18.png');
const imgScribble19 = require('../../../assets/scribbles/scribble_19.png');
const imgScribble2 = require('../../../assets/scribbles/scribble_2.png');
const imgScribble20 = require('../../../assets/scribbles/scribble_20@3x.png');
const imgScribble21 = require('../../../assets/scribbles/scribble_21.png');
const imgScribble22 = require('../../../assets/scribbles/scribble_22.png');
const imgScribble23 = require('../../../assets/scribbles/scribble_23.png');
const imgScribble24 = require('../../../assets/scribbles/scribble_24.png');
const imgScribble25 = require('../../../assets/scribbles/scribble_25.png');
const imgScribble26 = require('../../../assets/scribbles/scribble_26.png');
const imgScribble27 = require('../../../assets/scribbles/scribble_27.png');
const imgScribble28 = require('../../../assets/scribbles/scribble_28.png');
const imgScribble29 = require('../../../assets/scribbles/scribble_29.png');
const imgScribble3 = require('../../../assets/scribbles/scribble_3.png');
const imgScribble30 = require('../../../assets/scribbles/scribble_30.png');
const imgScribble31 = require('../../../assets/scribbles/scribble_31.png');
const imgScribble32 = require('../../../assets/scribbles/scribble_32.png');
const imgScribble33 = require('../../../assets/scribbles/scribble_33.png');
const imgScribble34 = require('../../../assets/scribbles/scribble_34.png');
const imgScribble35 = require('../../../assets/scribbles/scribble_35.png');
const imgScribble36 = require('../../../assets/scribbles/scribble_36.png');
const imgScribble37 = require('../../../assets/scribbles/scribble_37.png');
const imgScribble38 = require('../../../assets/scribbles/scribble_38.png');
const imgScribble39 = require('../../../assets/scribbles/scribble_39.png');
const imgScribble4 = require('../../../assets/scribbles/scribble_4.png');
const imgScribble40 = require('../../../assets/scribbles/scribble_40.png');
const imgScribble41 = require('../../../assets/scribbles/scribble_41.png');
const imgScribble42 = require('../../../assets/scribbles/scribble_42.png');
const imgScribble43 = require('../../../assets/scribbles/scribble_43.png');
const imgScribble44 = require('../../../assets/scribbles/scribble_44.png');
const imgScribble45 = require('../../../assets/scribbles/scribble_45.png');
const imgScribble46 = require('../../../assets/scribbles/scribble_46.png');
const imgScribble47 = require('../../../assets/scribbles/scribble_47.png');
const imgScribble48 = require('../../../assets/scribbles/scribble_48.png');
const imgScribble49 = require('../../../assets/scribbles/scribble_49.png');
const imgScribble5 = require('../../../assets/scribbles/scribble_5.png');
const imgScribble50 = require('../../../assets/scribbles/scribble_50.png');
const imgScribble51 = require('../../../assets/scribbles/scribble_51.png');
const imgScribble52 = require('../../../assets/scribbles/scribble_52.png');
const imgScribble53 = require('../../../assets/scribbles/scribble_53.png');
const imgScribble54 = require('../../../assets/scribbles/scribble_54.png');
const imgScribble55 = require('../../../assets/scribbles/scribble_55.png');
const imgScribble56 = require('../../../assets/scribbles/scribble_56.png');
const imgScribble57 = require('../../../assets/scribbles/scribble_57.png');
const imgScribble58 = require('../../../assets/scribbles/scribble_58.png');
const imgScribble59 = require('../../../assets/scribbles/scribble_59@3x.png');
const imgScribble6 = require('../../../assets/scribbles/scribble_6.png');
const imgScribble60 = require('../../../assets/scribbles/scribble_60.png');
const imgScribble61 = require('../../../assets/scribbles/scribble_61.png');
const imgScribble62 = require('../../../assets/scribbles/scribble_62.png');
const imgScribble63 = require('../../../assets/scribbles/scribble_63.png');
const imgScribble64 = require('../../../assets/scribbles/scribble_64.png');
const imgScribble65 = require('../../../assets/scribbles/scribble_65.png');
const imgScribble66 = require('../../../assets/scribbles/scribble_66.png');
const imgScribble67 = require('../../../assets/scribbles/scribble_67.png');
const imgScribble68 = require('../../../assets/scribbles/scribble_68.png');
const imgScribble69 = require('../../../assets/scribbles/scribble_69.png');
const imgScribble7 = require('../../../assets/scribbles/scribble_7.png');
const imgScribble70 = require('../../../assets/scribbles/scribble_70@3x.png');
const imgScribble71 = require('../../../assets/scribbles/scribble_71.png');
const imgScribble72 = require('../../../assets/scribbles/scribble_72.png');
const imgScribble74 = require('../../../assets/scribbles/scribble_74.png');
const imgScribble76 = require('../../../assets/scribbles/scribble_76.png');
const imgScribble77 = require('../../../assets/scribbles/scribble_77.png');
const imgScribble78 = require('../../../assets/scribbles/scribble_78.png');
const imgScribble79 = require('../../../assets/scribbles/scribble_79.png');
const imgScribble8 = require('../../../assets/scribbles/scribble_8.png');
const imgScribble80 = require('../../../assets/scribbles/scribble_80.png');
const imgScribble81 = require('../../../assets/scribbles/scribble_81.png');
const imgScribble82 = require('../../../assets/scribbles/scribble_82.png');
const imgScribble83 = require('../../../assets/scribbles/scribble_83.png');
const imgScribble84 = require('../../../assets/scribbles/scribble_84.png');
const imgScribble85 = require('../../../assets/scribbles/scribble_85.png');
const imgScribble86 = require('../../../assets/scribbles/scribble_86.png');
const imgScribble87 = require('../../../assets/scribbles/scribble_87.png');
const imgScribble88 = require('../../../assets/scribbles/scribble_88.png');
const imgScribble89 = require('../../../assets/scribbles/scribble_89.png');
const imgScribble9 = require('../../../assets/scribbles/scribble_9.png');
const imgScribble90 = require('../../../assets/scribbles/scribble_90.png');
const imgScribble91 = require('../../../assets/scribbles/scribble_91.png');
const imgScribble92 = require('../../../assets/scribbles/scribble_92.png');
const imgScribble93 = require('../../../assets/scribbles/scribble_93.png');
const imgScribble94 = require('../../../assets/scribbles/scribble_94.png');

export const scribblesMapping = {
  1: imgScribble1,
  2: imgScribble2,
  3: imgScribble3,
  4: imgScribble4,
  5: imgScribble5,
  6: imgScribble6,
  7: imgScribble7,
  8: imgScribble8,
  9: imgScribble9,
  10: imgScribble10,
  11: imgScribble11,
  12: imgScribble12,
  13: imgScribble13,
  14: imgScribble14,
  16: imgScribble16,
  17: imgScribble17,
  18: imgScribble18,
  19: imgScribble19,
  20: imgScribble20,
  21: imgScribble21,
  22: imgScribble22,
  23: imgScribble23,
  24: imgScribble24,
  25: imgScribble25,
  26: imgScribble26,
  27: imgScribble27,
  28: imgScribble28,
  29: imgScribble29,
  30: imgScribble30,
  31: imgScribble31,
  32: imgScribble32,
  33: imgScribble33,
  34: imgScribble34,
  35: imgScribble35,
  36: imgScribble36,
  37: imgScribble37,
  38: imgScribble38,
  39: imgScribble39,
  40: imgScribble40,
  41: imgScribble41,
  42: imgScribble42,
  43: imgScribble43,
  44: imgScribble44,
  45: imgScribble45,
  46: imgScribble46,
  47: imgScribble47,
  48: imgScribble48,
  49: imgScribble49,
  50: imgScribble50,
  51: imgScribble51,
  52: imgScribble52,
  53: imgScribble53,
  54: imgScribble54,
  55: imgScribble55,
  56: imgScribble56,
  57: imgScribble57,
  58: imgScribble58,
  59: imgScribble59,
  60: imgScribble60,
  61: imgScribble61,
  62: imgScribble62,
  63: imgScribble63,
  64: imgScribble64,
  65: imgScribble65,
  66: imgScribble66,
  67: imgScribble67,
  68: imgScribble68,
  69: imgScribble69,
  70: imgScribble70,
  71: imgScribble71,
  72: imgScribble72,
  74: imgScribble74,
  76: imgScribble76,
  77: imgScribble77,
  78: imgScribble78,
  79: imgScribble79,
  80: imgScribble80,
  81: imgScribble81,
  82: imgScribble82,
  83: imgScribble83,
  84: imgScribble84,
  85: imgScribble85,
  86: imgScribble86,
  87: imgScribble87,
  88: imgScribble88,
  89: imgScribble89,
  90: imgScribble90,
  91: imgScribble91,
  92: imgScribble92,
  93: imgScribble93,
  94: imgScribble94,
};

export const arrowsMapping = {
  1: imgArrow1,
  2: imgArrow2,
  3: imgArrow3,
  4: imgArrow4,
  5: imgArrow5,
  6: imgArrow6,
  7: imgArrow7,
  8: imgArrow8,
  9: imgArrow9,
  10: imgArrow10,
  11: imgArrow11,
  12: imgArrow12,
  13: imgArrow13,
  14: imgArrow14,
  15: imgArrow15,
  16: imgArrow16,
  17: imgArrow17,
  18: imgArrow18,
  19: imgArrow19,
  20: imgArrow20,
};

export default { scribblesMapping, arrowsMapping };
