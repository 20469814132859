import { Action } from 'types';
import { AuthState } from 'types';

import { Auth } from 'redux/actionTypes';

import moment from 'moment';

const initialState: AuthState = {
  authToken: null,
  expirationTime: moment().utc().toISOString(),
  isAuthenticated: false,
  loading: false,
  error: false,
  errorMessage: null,
};

export const authReducer = (state = initialState, action: Action): AuthState => {
  switch (action.type) {
    case Auth.SIGN_IN_SUCCESS:
      const {
        payload: { token: authToken, expirationTime },
      } = action;

      return { ...state, authToken, expirationTime, isAuthenticated: true };

    default:
      return state;
  }
};
