import { decamelizeKeys } from 'humps';

import { Auth } from 'redux/actionTypes';
import { SignupData } from 'types';
import { RetrieveUserAction } from 'types/user/actions';
import { API, callAPI } from 'utils';

export const signupRequest = (data: SignupData) => {
  return callAPI<any>({
    types: [Auth.SIGNUP_REQUEST, Auth.SIGNUP_SUCCESS, Auth.SIGNUP_FAILURE],
    endpoint: API.urls.signUp(),
    method: 'POST',
    body: decamelizeKeys(data),
    meta: { toast: false },
  });
};

export const createPortalSession = () => {
  return callAPI<any>({
    types: [
      Auth.CREATE_PORTAL_SESSION_REQUEST,
      Auth.CREATE_PORTAL_SESSION_SUCCESS,
      Auth.CREATE_PORTAL_SESSION_FAILURE,
    ],
    endpoint: API.urls.createPortalSession(),
    method: 'POST',
    meta: { toast: false },
  });
};

export const createCheckoutSession = (data: { priceId: string; couponCode?: string }) => {
  return callAPI<any>({
    types: [
      Auth.CREATE_CHECKOUT_SESSION_REQUEST,
      Auth.CREATE_CHECKOUT_SESSION_SUCCESS,
      Auth.CREATE_CHECKOUT_SESSION_FAILURE,
    ],
    endpoint: API.urls.createCheckoutSession(),
    method: 'POST',
    body: decamelizeKeys(data),
    meta: { toast: false },
  });
};

export const retrieveUser = () =>
  callAPI<RetrieveUserAction>({
    types: [Auth.RETRIEVE_USER_REQUEST, Auth.RETRIEVE_USER_SUCCESS, Auth.RETRIEVE_USER_FAILURE],
    endpoint: API.urls.user,
    method: 'GET',
    meta: { toast: false },
  });
