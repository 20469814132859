import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDAaqXO2ZMaqGReBYPIKOMZlKL-xisQqiU",
  authDomain: "sorted-packs.firebaseapp.com",
  databaseURL: "https://sorted-packs.firebaseio.com",
  projectId: "sorted-packs",
  storageBucket: "sorted-packs.appspot.com",
  messagingSenderId: "130479053229",
  appId: "1:130479053229:web:5950561d15101be590a924",
  measurementId: "G-6JGGJSK30H",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
