import { createSlice } from '@reduxjs/toolkit';
import { camelizeKeys } from 'humps';

import { API, buildParams, URLParamObject, createAsyncThunk } from 'utils';

import { Pack, PackResponse } from 'types';

export type PackState = Record<string, Pack>;

export const initialState: PackState = {};

export const fetchPack = createAsyncThunk<PackResponse, { uid: string, queryParams?: URLParamObject }>(
  'pack/fetch',
  async (api, args) => {
    const params = buildParams(args.queryParams);
    const res = await api.get(API.urls.packSummary(args.uid) + (params ? `?${params}` : ''));
    return res.data;
  },
);

export const packsSlice = createSlice({
  name: 'packs',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPack.fulfilled, (state, action) => {
      return {
        ...state,
        [action.payload.uid]: {
          ...camelizeKeys(action.payload),
          recipes: action.payload.recipes.map(recipe => recipe.uid),
        } as Pack,
      };
    });
  },
});

export default packsSlice.reducer;
