import { signInWithEmailAndPassword, UserCredential } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserId, logEvent } from '@amplitude/analytics-browser';
import { Helmet } from 'react-helmet';

import { Heading, Flex, Text, useToast } from '@workshop/ui';

import { useLocation } from 'react-router-dom';
import { authActions } from 'redux/actions';
import { LoginForm } from 'screens/Login/LoginForm';
import { GlobalState, LoginData, SignInSuccess, UserInfo } from 'types';
import { auth, getParamFromUrl, getPortalErrorMessage } from 'utils';
import { FirebaseError } from 'firebase/app';

const logoSmall = require('../../../assets/logo_small.png');
const aubergineImg = require('../../../assets/courgette@3x.png');
const logoGopuffHoriz = require('../../../assets/logo_gopuff_horiz.png');
const logoGopuffVert = require('../../../assets/logo_gopuff_vert.png');

const signInSuccess = (userInfo: UserInfo): SignInSuccess => ({
  type: 'SIGN_IN_SUCCESS',
  payload: { ...userInfo },
});

const LoginScreen: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [signInCompleted, setSignInCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast({
    position: 'top',
    duration: 3000,
    containerStyle: { backgroundColor: 'statusError' },
  });

  const location = useLocation();
  const priceId = getParamFromUrl(location, 'price_id');
  const partner = getParamFromUrl(location, 'partner');
  const couponCode = getParamFromUrl(location, 'coupon_code');

  const authToken = useSelector((state: GlobalState) => state.auth.authToken);

  const onSubmit = async ({ email, password }: LoginData) => {
    setIsLoading(true);
    // Attempt to sign in via Firebase after successful signup
    // (i.e, authenticate and retrieve the auth token)
    let userCredential: UserCredential | undefined;
    try {
      userCredential = await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      const error = err as FirebaseError;

      let errorMessage = '';
      switch (error.code) {
        case 'auth/wrong-password':
          errorMessage = 'Invalid username/password combination. Please try again.';
          break;

        case 'auth/user-not-found':
          errorMessage =
            'We could not find a user with this email address in our system. Please try again.';
          break;

        default:
          errorMessage = 'Login failed. Please try again.';
          break;
      }

      toast({
        title: errorMessage,
        status: 'error',
        duration: 1000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }

    if (!userCredential) {
      toast({
        title: 'Failed to retrieve credentials. Please reresh the page and try again.',
        status: 'error',
        duration: 1000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }

    const token = await userCredential.user.getIdTokenResult();

    // Send the token to the redux store
    dispatch(
      signInSuccess({
        token: token.token,
        expirationTime: token.expirationTime,
        displayName: userCredential.user.displayName,
      }),
    );

    setSignInCompleted(true);
  };

  useEffect(() => {
    if (!authToken || !signInCompleted) return;

    const postLoginRedirect = async () => {
      const user = await dispatch(authActions.retrieveUser());

      // @ts-ignore
      const userId: number = user?.payload?.id;
      // @ts-ignore
      const uid: string = user?.payload?.uid;
      // @ts-ignore
      const email: string = user?.payload?.email;

      if (userId) {
        setUserId(userId.toString());
        await logEvent('web_app_login').promise;
      }

      if (uid) {
        _cio.identify({
          // Required attributes
          id: uid,
          email: email,
        });
        _cio.track('web_app_login');
      }

      // If a priceId is present in the location params, call the create-checkout endpoint,
      // otherwise fall back to the create-portal endpoint.
      const nextAction =
        priceId !== null
        ? authActions.createCheckoutSession({ priceId, ...(couponCode ? { couponCode } : {}) })
          : authActions.createPortalSession();

      const checkoutRes = await dispatch(nextAction);

      // @ts-ignore
      if (checkoutRes?.payload?.url) {
        // @ts-ignore
        window.location.href = checkoutRes?.payload?.url;
        return;
      }

      // @ts-ignore
      setErrorMessage(getPortalErrorMessage(checkoutRes?.payload?.error?.message));
      setIsLoading(false);
    };

    postLoginRedirect();
  }, [authToken, signInCompleted]);

  return (
    <Flex flexDir={['column', 'column', 'row']}>
      <Helmet>
        <title>Sidekick - Sign in</title>
      </Helmet>
      <Flex
        display={['none', 'none', 'flex']}
        backgroundImage={logoSmall}
        width="28px"
        height="28px"
        position="absolute"
        left={75}
        top={50}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
      />
      <Flex
        flex="0 0 40%"
        backgroundColor="primary"
        flexDirection="column"
        alignItems="flex-start"
        minHeight={['0', '0', '100vh']}
        pt={['10', '10', '20']}
        pb="10"
        px="20"
      >
        <Flex pt={['0', '0', '20']} width="100%" />
        {partner !== 'gopuff' && (
          <Text textAlign="left" fontWeight="bold" fontSize={['lg', 'lg', 'xl']}>
            Welcome back to Sidekick
          </Text>
        )}
        <Flex
          display={partner === 'gopuff' ? 'block' : ['none', 'none', 'block']}
          width={250}
          height={[50, 150, 250]}
          mt="8"
          backgroundImage={
            partner === 'gopuff' ? [logoGopuffHoriz, logoGopuffVert, logoGopuffVert] : aubergineImg
          }
          backgroundRepeat="no-repeat"
          backgroundSize="contain"
          backgroundPosition="center"
        />
        {partner === 'gopuff' && (
          <Text textAlign="left" fontWeight="bold" fontSize={['lg', 'lg', 'xl']}>
            Sign in & Redeem Your Coupon Prize Now!
          </Text>
        )}
      </Flex>
      {errorMessage ? (
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          minHeight="100vh"
          backgroundColor="white"
          flex={1}
          p="20"
          pt={['10', '10', '20']}
        >
          <Flex pt={['0', '0', '20']} width="100%" />
          <Heading fontSize={['xl', 'xl', 'xl']} mb="8">
            {errorMessage}
          </Heading>
          <Flex flex={1} />
        </Flex>
      ) : (
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          minHeight="100vh"
          backgroundColor="white"
          flex={1}
          p="20"
          pt={['10', '10', '20']}
        >
          <Flex pt={['0', '0', '20']} width="100%" />
          <Heading fontSize={['2xl', '2xl', '4xl']} mb="8">
            Sign in to get started
          </Heading>
          <LoginForm onSubmit={onSubmit} isLoading={isLoading} />
        </Flex>
      )}
    </Flex>
  );
};

export default LoginScreen;
