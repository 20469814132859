import { createAsyncThunk as BaseCreateAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios, { AxiosInstance } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import { GlobalState } from 'types';
import API from './ApiHelper';

const selectAuthToken = (state: GlobalState) => state.auth.authToken;

export const authTokenSelector = createSelector([selectAuthToken], token => token);

const http = async (thunkApi: { dispatch: any; getState: () => GlobalState }) => {
  const {
    // dispatch,
    getState,
  } = thunkApi;

  let { authToken } = getState().auth;
  // const { expirationTime: currentExpirationTime } = getState().auth;

  // On every API call, check whether the user's Firebase ID Token is close to expiring.

  // If we're within 5 minutes of the token expiring, then we forcefully generate
  // a new one and update our record of when the new token expires
  // const shouldRefresh = moment.utc(currentExpirationTime).subtract(5, 'minutes') < moment.utc();

  // if (shouldRefresh) {
  //   const user = auth().currentUser;
  //   if (user) {
  //     authToken = await refreshAuthToken(user, dispatch);
  //   }
  // }

  return applyCaseMiddleware(
    axios.create({
      baseURL: API.baseURL,
      headers: {
        'Content-type': 'application/json',
        ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
        Accept: `application/json; version=${API.version}`,
      },
    }),
    { preservedKeys: [] },
  );
};

export function createAsyncThunk<ReturnType, ArgsType = undefined>(
  typePrefix: string,
  callback: (axios: AxiosInstance, args: ArgsType, state: GlobalState) => Promise<ReturnType>,
) {
  return BaseCreateAsyncThunk<ReturnType, ArgsType, { state: GlobalState }>(
    typePrefix,
    async (params, thunkApi) => {
      const api = await http(thunkApi);
      return callback(api, params, thunkApi.getState());
    },
  );
}

export function isValidPayload<T>(
  argument: T | any | undefined,
  expectedType: 'array' | 'object' | 'string',
): argument is T {
  switch (expectedType) {
    case 'array':
      return Array.isArray(argument);
    case 'object':
      return typeof argument === 'object';
    case 'string':
      return typeof argument === 'string';
    default:
      return false;
  }
}

export default http;
