import { camelizeKeys } from "humps";
import get from "lodash.get";
import { normalize, Schema } from "normalizr";
import { getJSON, RSAA, RSAAAction } from "redux-api-middleware";

import { ThunkAction } from "types";
import { auth, getCookie } from "utils/index";

import { APIErrorResponse, ToastMetaParams } from "types";

import moment from "moment";

export interface MetaType {
  [key: string]: any;
  // Define how to handle toast success/error messages
  // for this request
  toast?: ToastMetaParams;
  // Define whether to show a 404 page for the given pathname
  // in case this requests results in a 404 error
  pathname404?: string;
}
export interface CallAPI {
  endpoint: string;
  method?: string;
  types: string[];
  body?: Object;
  headers?: { [key: string]: string };
  credentials?: "omit" | "same-origin" | "include";
  // Optional schema: used as the basis for normalization
  schema?: Schema;
  // Optional dot notation path of data to normalize: If provided,
  // will be used to set which data to normalize within our JSON.
  // By default, the JSON returned from the API is normalized at
  // its root. If a path is supplied, e.g. 'results' then 'json.results'
  // will be normalized
  path?: string;
  bailout?: Function | boolean;
  meta?: MetaType;
  fetchNextPage?: ((nextUrl: string) => Promise<any>) | null | undefined;
  noAuth?: boolean;
}

/**
 * Helper function to sit on top of `redux-api-middleware` and provide us
 * with a simple call signature when interacting with the API.
 *
 * callAPI({
 *  endpoint: 'test/',
 *  method: 'GET',
 *  types: [
 *    'GET_TEST_REQUEST',
 *    'GET_TEST_SUCCESS',
 *    'GET_TEST_FAILURE',
 *  ],
 * })
 * // => ThunkAction
 *
 *
 * callAPI({
 *  endpoint: 'test/',
 *  method: 'POST',
 *  types: [
 *    'POST_TEST_REQUEST',
 *    'POST_TEST_SUCCESS',
 *    'POST_TEST_FAILURE',
 *  ],
 *  body: decamelizeKeys(body),
 * })
 * // => ThunkAction
 *
 */
function callAPI<T>({
  endpoint,
  method = "GET",
  types,
  body,
  headers,
  credentials = "include",
  schema,
  path,
  bailout,
  meta,
  noAuth = false,
  fetchNextPage,
}: CallAPI): ThunkAction<T> {
  // Make our actionTypes for redux-api-middleware
  const makeTypes = types
    .map((type) => {
      // If we have a request body, add it to the request
      if (type.includes("REQUEST")) {
        return {
          type,
          meta,
          ...(body ? { payload: body } : {}),
        };
      }

      // If we have a schema, we want to normalize.
      if (type.includes("SUCCESS")) {
        return {
          type,
          meta,
          payload: (action: RSAAAction, state: any, res: Response) =>
            getJSON(res)
              .then(async (res) => {
                // Fetch the next page if api is paginated and has a next page url
                res &&
                  res.next &&
                  fetchNextPage &&
                  // TODO: DRF returns 'next' url with incorrect protocol
                  (await fetchNextPage(res.next.includes("localhost") ? res.next : res.next.replace("http", "https")));
                return res;
              })
              .then(camelizeKeys)
              // If a custom normalize function has been provided, use that, otherwise
              // default to using the supplied schema to normalize the JSON at the
              // top level.
              .then((json) => {
                return schema ? normalize(path ? get(json, path) : json, schema) : json;
              }),
        };
      }

      if (type.includes("FAILURE")) {
        return {
          type,
          meta,
          payload: (action: RSAAAction, state: any, res: Response) => {
            return getJSON(res).then((error: APIErrorResponse) => {
              return error || res;
            });
          },
        };
      }

      return null;
    })
    .filter((a) => a);

  const finalHeaders: { [key: string]: string } = {
    "Content-Type": "application/json",
    ...headers,
    "X-CSRFToken": getCookie("csrftoken"),
  };

  // For 'Content-Type':'multipart/form-data', the Browser need to calculate the Boundary & set the content-type itself
  // Also we do not want to stringify it as it is a FormData type
  const isFormData = body instanceof FormData;
  if (isFormData) delete finalHeaders["Content-Type"];

  return (dispatch, getState) => {
    let { authToken } = getState().auth;
    const { expirationTime: currentExpirationTime } = getState().auth;

    // On every API call, check whether the user's Firebase ID Token is close to
    // expiring.

    // If we're within 5 minutes of the token expiring, then we forcefully generate
    // a new one and update our record of when the new token expires
    const shouldRefresh = moment.utc(currentExpirationTime).subtract(5, "minutes") < moment.utc();

    if (shouldRefresh) {
      const user = auth.currentUser;
      if (user) {
        // authToken = await refreshAuthToken(user, dispatch);
      }
    }

    const headers = {
      ...finalHeaders,
      ...(authToken && !noAuth ? { Authorization: `Bearer ${authToken}` } : {}),
    };

    // @ts-ignore
    return dispatch({
      //@ts-ignore
      [RSAA]: {
        fetch,
        options: { redirect: "follow" },
        endpoint,
        method,
        headers,
        types: makeTypes,
        credentials,
        ...(body ? { body: isFormData ? body : JSON.stringify(body) } : {}),
        bailout: bailout || false,
        ok: (res: Response) => {
          // Custom handler to treat 302 as success
          return res.status < 400 && res.status >= 200;
        },
      },
    });
  };
}

export default callAPI;
