// export const ENV = 'local';
export const ENV = 'dev';
// export const ENV = 'prod';

type Env = 'local' | 'dev' | 'prod';

export const BACKEND_ENV = process.env.REACT_APP_BACKEND_ENV
  ? (process.env.REACT_APP_BACKEND_ENV as Env)
  : ENV;

const baseEnvUrls = {
  local: 'http://localhost:8000/',
  dev: 'https://cook-dev.sorted.club/',
  prod: 'https://cook.sorted.club/',
} as const;

const apiRoot = 'api/';

/**
 * This class exists to allow us to switch between different environments
 * at runtime - i.e, when testing, to switch between prod and dev.
 *
 * A single class instance is created, and we call setBaseEnv from the
 * main App.tsx before we start interacting with the API, if/when required
 * (i.e if a staff user has switched environments).
 */
class ApiHelper {
  baseURL: string;

  apiURL: string;

  version: string;

  urls: ReturnType<this['getUrls']>;

  constructor() {
    this.baseURL = baseEnvUrls[BACKEND_ENV];
    this.apiURL = `${this.baseURL}${apiRoot}`;
    this.version = '2022-08-27';
    this.urls = (this.getUrls as () => ReturnType<this['getUrls']>)();
  }

  setVersion = (version: string) => {
    this.version = version;
  };

  setBaseEnv = (env: keyof typeof baseEnvUrls) => {
    this.baseURL = baseEnvUrls[env];
    this.apiURL = `${this.baseURL}${apiRoot}`;
    this.urls = (this.getUrls as () => ReturnType<this['getUrls']>)();
  };

  getUrls = () => ({
    baseURL: this.baseURL,
    // Auth API
    login: `${this.baseURL}auth/login/`,
    signUp: () => `${this.apiURL}signup/create-account`,
    signUpSSO: `${this.apiURL}user/register/sso`,
    deleteUser: `${this.apiURL}user/delete/`,
    // Checkout API
    checkoutConfig: `${this.apiURL}checkout/config`,
    // TODO
    changePassword: `${this.baseURL}auth/changePassword/`,
    resetPassword: (key?: string, uidb36?: string) => `${this.baseURL}auth/resetPassword/`,
    // Checkout
    createCheckoutSession: () => `${this.apiURL}checkout/create-session`,
    createPortalSession: () => `${this.apiURL}portal/create-session`,
    // User API
    user: `${this.apiURL}user/`,
    listCreateUserPack: (queryStr?: string) =>
      `${this.apiURL}user/packs/${queryStr ? `?${queryStr}` : ''}`,
    retrieveUpdateUserPack: (id: number) => `${this.apiURL}user/packs/${id}/`,
    retrieveUpdateUserRecipe: (id: number) => `${this.apiURL}user/recipes/${id}/`,
    // Recipe & Pack API
    pack: (id: number | string) => `${this.apiURL}pack/${id}/`,
    packSummary: (id: number | string) => `${this.apiURL}pack/${id}/summary/`,
    packs: (queryStr?: string) => `${this.apiURL}pack/${queryStr ? `?${queryStr}` : ''}`,
    searchPacks: (queryStr: string) => `${this.apiURL}search/?${queryStr}`,
    recipe: (id: number | string) => `${this.apiURL}recipe/${id}/`,
    recipeSummary: (id: number | string) => `${this.apiURL}recipe/${id}/summary/`,
    ingredients: `${this.apiURL}ingredients/`,
    createTwist: `${this.apiURL}twists/`,
    // Like API
    createUserLike: `${this.apiURL}likes/add/`,
    deleteUserLike: `${this.apiURL}likes/remove/`,
    // Category API
    categories: (id?: number, queryStr?: string) =>
      `${this.apiURL}categories/${id ? `${id}/` : ''}${queryStr ? `?${queryStr}` : ''}`,
    // Menu API
    menu: `${this.apiURL}menu`,
    // Planner API
    plannerWeek: (id?: number, queryStr?: string) =>
      `${this.apiURL}planner/week/${id ? `${id}/` : ''}${queryStr ? `?${queryStr}` : ''}`,
    plannerDay: (id?: number) => `${this.apiURL}planner/day/${id ? `${id}/` : ''}`,
    plannerDayDelete: (queryStr: string) => `${this.apiURL}planner/week/clear/?${queryStr}`,
    // Membership API
    createUpdateMembership: `${this.apiURL}membership/`,
    validateMembership: `${this.apiURL}validateMembership/`,
    // Session Activity API
    sessionActivity: `${this.apiURL}activity/`,
    // Config API
    paywallConfig: `${this.apiURL}config/paywall`,
    // Misc API
    signupReasons: `${this.apiURL}signupReasons/`,
    // External URLs
    maintenanceCheck: 'https://api-status.sorted.club',
    jiraIssueColector:
      'https://sortedfood.atlassian.net/rest/collectors/1.0/template/custom/4e3154ad',
    // Deprecated APIs
    listCreateFavourite: `${this.apiURL}favourites/`,
    deleteFavourite: (id: number) => `${this.apiURL}favourites/${id}/`,
    deviceToken: (id?: number) => `${this.apiURL}notification/deviceToken/${id ? `${id}/` : ''}`,
    // Search Ingredient API
    searchIngredient: (query: string, limit: 10) =>
      `${this.apiURL}ingredientDislikes/?query=${query}&limit=${limit}`,
  });
}

const API = new ApiHelper();

export default API;
