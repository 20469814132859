import { CheckoutScreen } from 'screens/Checkout';
import { LoginScreen } from 'screens/Login';
import { SignupScreen } from 'screens/Signup';
import { ForgotPasswordScreen } from 'screens/ForgotPassword';
import { RecipeScreen } from 'screens/Recipe';
import { PackScreen } from 'screens/Pack';
import { DownloadSidekick } from 'screens/DownloadSidekick';

export interface Route {
  /** The component/screen to render */
  component: any;
  /** Name to display in the breadcrumbs */
  name: string;
  /** The route path */
  path: (...arg: any[]) => string;
}

export interface Routes {
  /* Public routes - accessible by everyone only when logged out */
  public: { [key: string]: Route };
}

const routes: Routes = {
  public: {
    download: {
      path: () => '/download',
      name: 'Shop',
      component: DownloadSidekick,
    },
    // checkout: {
    //   path: () => '/checkout',
    //   name: 'Checkout',
    //   component: DownloadSidekick,
    // },
    login: {
      path: () => '/login',
      name: 'Login',
      component: LoginScreen,
    },
    // signup: {
    //   path: () => '/signup',
    //   name: 'Sign Up',
    //   component: DownloadSidekick,
    // },
    forgotPassword: {
      path: () => '/password',
      name: 'Forgot Password',
      component: ForgotPasswordScreen,
    },
    recipe: {
      path: () => '/recipes/:recipeUid',
      name: 'Recipe',
      component: RecipeScreen,
    },
    pack: {
      path: () => '/packs/:packUid',
      name: 'Pack',
      component: PackScreen,
    },
    invite: {
      path: () => '/invite/:uid',
      name: 'Pack',
      component: DownloadSidekick,
    },
    menu: {
      path: () => '/menu',
      name: 'Menu',
      component: DownloadSidekick,
    },
    shop: {
      path: () => '/shopping-list',
      name: 'Shop',
      component: DownloadSidekick,
    },
  },
};

export default routes;
