import { applyMiddleware, compose, createStore } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import * as Sentry from '@sentry/react';

import { Action, GlobalState } from 'types';

import rootReducer from 'redux/reducers';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const middleware = [thunk as ThunkMiddleware<GlobalState, Action>, apiMiddleware];
let enhancers = compose(applyMiddleware(...middleware), sentryReduxEnhancer);

// if (ENV !== "prod") {
//   const composeEnhancers: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//   enhancers = composeEnhancers(applyMiddleware(...middleware), sentryReduxEnhancer);
// }

const store = createStore(rootReducer, enhancers);

export type AppDispatch = typeof store.dispatch;

// Try the below if we're having issues with redux-thunk typescript
// - https://github.com/reduxjs/redux-thunk/issues/103
//
// export default createStore<
//   GlobalState,
//   Action,
//   {
//     dispatch: unknown;
//   },
//   {}
// >(rootReducer, enhancers);

export default store;
