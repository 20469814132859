export * from './buildParams';
export { default as callAPI } from './callAPI';
export { default as getCookie } from './getCookie';
export { default as API } from './ApiHelper';
export { getParamFromUrl } from './getParamFromUrl';
export { default as FirebaseApp, auth } from './firebase';
export { createAsyncThunk } from './httpHelper';
export * from './portionSize';
export { default as getCookingTime } from './getCookingTime';

export const getPortalErrorMessage = (err: string | null) => {
  if (!err) {
    return 'Unable to proceed to checkout. Please refresh the page and try again.';
  }
  return err.replace('app_store', 'the Apple Store').replace('play_store', 'Google Play');
};
