import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';

import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Text } from '@workshop/ui';

import { useAuthError } from 'redux/selectors/auth';

type FormData = { email: string };

interface ForgotPasswordFormProps {
  onSubmit: (data: FormData) => Promise<void>;
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ onSubmit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    // triggerValidation,
  } = useForm<FormData>({
    defaultValues: {},
  });

  const { errorMessage } = useAuthError();

  const onSubmitData = handleSubmit(async ({ email }) => {
    setIsSubmitting(true);
    await onSubmit({ email });
    setIsSubmitting(false);
  });

  return (
    <Flex flex={1} width="100%" maxW={500}>
      <Flex flexDirection="column" flex={1}>
        <Flex flexDirection="column">
          <FormControl isInvalid={Boolean(errors.email)}>
            <FormLabel fontSize="sm" style={{ color: '#3C4257' }}>
              Email
            </FormLabel>
            <Input
              {...register('email', {
                required: true,
                validate: value => isEmail(value),
              })}
              id="email"
              name="email"
              placeholder="Email"
              type="email"
            />
            <FormErrorMessage color="statusError">
              Please enter a valid email address
            </FormErrorMessage>
          </FormControl>
        </Flex>

        <FormControl isInvalid={Boolean(errorMessage)}>
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControl>
        <Button
          isDisabled={isSubmitting || Object.values(touchedFields).length === 0}
          isLoading={isSubmitting}
          mt={2}
          onClick={onSubmitData}
          variant="solid"
          label="Continue"
        />
      </Flex>
    </Flex>
  );
};

export default ForgotPasswordForm;
