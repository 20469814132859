import React from 'react';

import { CustomIcon } from '@workshop/ui';
import { DietaryTag } from 'types';

const FishIcon = require('../../../assets/svg/fish.svg');
const MeatIcon = require('../../../assets/svg/meat.svg');
const MeatAndFishIcon = require('../../../assets/svg/meat_and_fish.svg');
const VeganIcon = require('../../../assets/svg/vegan.svg');
const VegIcon = require('../../../assets/svg/vegetarian.svg');

const svgProps = {
  width: '100%',
  height: '100%',
  fill: 'black',
};

const iconMap = {
  [DietaryTag.meat]: {
    component: <CustomIcon {...svgProps} alt="meat" src={MeatIcon.default} />,
    name: 'Meat',
    iconColor: 'meatIcon',
    backgroundColor: 'meatBackground',
    backgroundOpacity: 'meatBackgroundOpacity',
  },
  [DietaryTag.meatAndFish]: {
    component: <CustomIcon {...svgProps} alt="meat and fish" src={MeatAndFishIcon.default} />,
    name: 'Meat & Fish',
    iconColor: 'meatFishIcon',
    backgroundColor: 'meatFishBackground',
    backgroundOpacity: 'meatFishBackgroundOpacity',
  },
  [DietaryTag.fish]: {
    component: <CustomIcon {...svgProps} alt="fish" src={FishIcon.default} />,
    name: 'Fish',
    iconColor: 'fishIcon',
    backgroundColor: 'fishBackground',
    backgroundOpacity: 'fishBackgroundOpacity',
  },
  [DietaryTag.veg]: {
    component: <CustomIcon {...svgProps} alt="veg" src={VegIcon.default} />,
    name: 'Veggie',
    iconColor: 'vegIcon',
    backgroundColor: 'vegBackground',
    backgroundOpacity: 'vegBackgroundOpacity',
  },
  [DietaryTag.vegan]: {
    component: <CustomIcon {...svgProps} alt="vegan" src={VeganIcon.default} />,
    name: 'Vegan',
    iconColor: 'veganIcon',
    backgroundColor: 'veganBackground',
    backgroundOpacity: 'veganBackgroundOpacity',
  },
} as const;

const getDietaryIcon = (dietaryTag: DietaryTag | undefined) => {
  if (!dietaryTag) return null;
  return iconMap[dietaryTag];
};

export default getDietaryIcon;
