import { theme } from '@chakra-ui/react';

const baseSize: typeof theme.sizes = {
  ...theme.sizes,
  '28': '7rem',
};

export default {
  ...baseSize,
  image: {
    '3xs': baseSize[6],
    '2xs': baseSize[8],
    xs: baseSize[12],
    sm: baseSize[16],
    md: baseSize[20],
    lg: baseSize[28],
    xl: baseSize[36],
    '2xl': baseSize[48],
    '3xl': baseSize[64],
    max: '600px',
  },
  screen: {
    sm: baseSize['3xl'], // ~ 750px
    md: baseSize['4xl'], // ~ 900px
    lg: baseSize['5xl'], // ~1000px
    xl: baseSize['6xl'], // ~ 1150px
  },
  icon: baseSize[4],
  avatar: baseSize[12],
};
