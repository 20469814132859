import {
  Box,
  CustomIcon,
  Flex,
  generateTags,
  Link as ExternalLink,
  Skeleton,
  TagCloud,
  Text,
} from '@workshop/ui';
import { Banner } from 'components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { fetchRecipe } from 'redux/reducers/recipe';
import { GlobalState } from 'types';
import { getCookingTime, getPortionSize } from 'utils';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

const equipmentMin = require('../../../assets/equipment-min.png');
const ingredientsMin = require('../../../assets/ingredients-min.png');
const confusedTomato = require('../../../assets/confused_tomato.png');

const testimonials = [
  require('../../../assets/testimonials/quote_01-min.png'),
  require('../../../assets/testimonials/quote_02-min.png'),
  require('../../../assets/testimonials/quote_03-min.png'),
  require('../../../assets/testimonials/quote_04-min.png'),
  require('../../../assets/testimonials/quote_05-min.png'),
  require('../../../assets/testimonials/quote_06-min.png'),
  require('../../../assets/testimonials/quote_07-min.png'),
  require('../../../assets/testimonials/quote_08-min.png'),
  require('../../../assets/testimonials/quote_09-min.png'),
  require('../../../assets/testimonials/quote_10-min.png'),
  require('../../../assets/testimonials/quote_11-min.png'),
  require('../../../assets/testimonials/quote_12-min.png'),
  require('../../../assets/testimonials/quote_13-min.png'),
];

interface MatchParams {
  recipeUid: string;
}

const extractNumber = (str: string | null | undefined) => {
  const match = str?.match(/\d+/);
  return match ? parseInt(match[0], 10) : 0;
};

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

// Props passed to our component from parents
interface RecipeScreenProps extends RouteComponentProps<MatchParams> {}

const RecipeScreen: React.FC<RecipeScreenProps> = ({
  match: {
    params: { recipeUid },
  },
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<'ingredients' | 'equipment'>('ingredients');
  const dispatch = useDispatch();

  const recipe = useSelector((state: GlobalState) => state.recipe[recipeUid]);

  const numPeople = getPortionSize(null);
  const cookingTime = getCookingTime(recipe, numPeople);
  const isYoutubeRecipe = recipe?.recipeType === 'youtube';

  useEffect(() => {
    const loadData = async () => {
      await dispatch(fetchRecipe({ uid: recipeUid }));
      await sleep(1000);
      setIsLoading(false);
    };

    loadData();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      (window as any).prerenderReady = true;
    }
  }, [isLoading]);

  if (!isLoading && !recipe) {
    return (
      <>
        <Flex display={['none', 'none', 'flex']}>
          <Banner isRecipe />
        </Flex>
        <Flex flexDir="column" justifyContent="center" alignItems="center" pt={['4', '4', '10']}>
          <Flex
            width={250}
            height={250}
            maxW="70vw"
            mt="2"
            mb="2"
            backgroundImage={confusedTomato}
            backgroundRepeat="no-repeat"
            backgroundSize="contain"
            backgroundPosition="center"
          />
          <Flex px="4">
            <Text fontWeight={400} fontSize="22px" lineHeight="22px" textAlign="center">
              We couldn't find what you were looking for 😞
            </Text>
          </Flex>
        </Flex>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{isLoading ? 'Sidekick - Recipe' : `Recipe - ${recipe?.title}`}</title>
        <meta name="description" content={recipe?.story} />
        <meta name="og:image" content={recipe?.resizedImages?.medium} />
      </Helmet>
      <Flex display={['none', 'none', 'flex']}>
        <Banner isRecipe />
      </Flex>
      <Flex flexDir="column" justifyContent="center" alignItems="center" pt={['4', '4', '10']}>
        <Flex flexDirection={['column', 'column', 'row']} width={['100vw', '100vw', '70vw']}>
          <Flex flexDir="column" flex={[1, 1, 2]} px="4">
            <Skeleton isLoaded={!isLoading} mb="2">
              <Text
                fontFamily="Greed"
                textAlign="left"
                fontWeight="bold"
                display={['flex', 'flex', 'none']}
                fontSize="20px"
                textTransform="capitalize"
              >
                {isLoading ? 'Loading...' : recipe?.title}
              </Text>
            </Skeleton>
            <TagCloud
              display={['flex', 'flex', 'none']}
              mb="4"
              justifyContent="flex-start"
              alignItems="flex-start"
              tags={generateTags({
                numPeople: extractNumber(recipe?.portionSize) || numPeople,
                dietaryTag: recipe?.dietaryTag,
                cookingTime: cookingTime?.toString(),
                ingredientsCount: recipe?.ingredientCount,
                isYoutube: isYoutubeRecipe,
                isLoading,
                tagProps: { mr: '1', iconSize: 15.5 },
              })}
            />
            <Skeleton isLoaded={!isLoading}>
              <Flex
                borderRadius="md"
                backgroundImage={recipe?.resizedImages?.medium || ''}
                width="100%"
                pt="110%"
                backgroundSize="cover"
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
              />
            </Skeleton>
          </Flex>
          <Flex flexDir="column" flex={[1, 1, 3]} px="4" mt={['4', '4', '0']}>
            <Skeleton isLoaded={!isLoading} mt="6">
              <Text
                fontFamily="Greed"
                textAlign="left"
                fontWeight="bold"
                fontSize="28px"
                display={['none', 'none', 'flex']}
                lineHeight="28px"
                textTransform="capitalize"
              >
                {isLoading ? 'Loading...' : recipe?.title}
              </Text>
            </Skeleton>
            <TagCloud
              display={['none', 'none', 'flex']}
              mt="2"
              justifyContent="flex-start"
              alignItems="flex-start"
              tags={generateTags({
                numPeople: extractNumber(recipe?.portionSize) || numPeople,
                dietaryTag: recipe?.dietaryTag,
                cookingTime: cookingTime?.toString(),
                ingredientsCount: recipe?.ingredientCount,
                isYoutube: isYoutubeRecipe,
                isLoading,
                tagProps: { mr: '1', iconSize: 15.5 },
              })}
            />

            <Box mt="4" />
            <Skeleton isLoaded={!isLoading}>
              <Flex backgroundColor="secondaryBackground" borderRadius="md" py="4" px="6">
                <Text
                  fontFamily="Greed"
                  textAlign="left"
                  fontSize={['sm', 'sm', 'sm']}
                  lineHeight="18px"
                  letterSpacing={0.3}
                >
                  {recipe?.story}
                </Text>
              </Flex>
            </Skeleton>
            <Flex flexDir="row" width="100%" pt="4">
              <Flex
                width="50%"
                flexDir="column"
                pr="2"
                alignItems={['center', 'center', 'flex-start']}
              >
                <Skeleton isLoaded={!isLoading} borderRadius="lg" width={['100%', '100%', 'auto']}>
                  <Flex
                    width={['100%', '100%', 'auto']}
                    backgroundColor={
                      activeTab === 'ingredients'
                        ? ['secondaryBackground', 'secondaryBackground', 'secondaryBackground']
                        : ['white', 'white', 'secondaryBackground']
                    }
                    borderWidth={1}
                    borderColor={
                      activeTab === 'ingredients'
                        ? ['secondaryBackground', 'secondaryBackground', 'secondaryBackground']
                        : ['lightGrey', 'lightGrey', 'secondaryBackground']
                    }
                    py="1"
                    px="4"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="lg"
                    minH={8}
                    display="flex"
                    flex={0}
                    onClick={() => setActiveTab('ingredients')}
                    _hover={{ cursor: 'pointer' }}
                  >
                    <CustomIcon name="nutrition" size="md" mr="2" />
                    <Text fontSize="xs" fontWeight="bold" fontFamily="Greed" letterSpacing="0.6px">
                      INGREDIENTS
                    </Text>
                  </Flex>
                </Skeleton>
                {isLoading ? (
                  <Skeleton
                    isLoaded={false}
                    loadingStyle={{ width: '100%', height: '200px', marginTop: '4' }}
                  />
                ) : (
                  <Flex
                    mt="4"
                    display={['none', 'none', 'flex']}
                    backgroundImage={ingredientsMin}
                    width="100%"
                    height="200px"
                    backgroundSize="contain"
                    backgroundRepeat="no-repeat"
                  />
                )}
              </Flex>
              <Flex
                width="50%"
                flexDir="column"
                pl="2"
                alignItems={['center', 'center', 'flex-start']}
              >
                <Skeleton isLoaded={!isLoading} borderRadius="lg" width={['100%', '100%', 'auto']}>
                  <Flex
                    width={['100%', '100%', 'auto']}
                    backgroundColor={
                      activeTab === 'equipment'
                        ? ['secondaryBackground', 'secondaryBackground', 'secondaryBackground']
                        : ['white', 'white', 'secondaryBackground']
                    }
                    borderWidth={1}
                    borderColor={
                      activeTab === 'equipment'
                        ? ['secondaryBackground', 'secondaryBackground', 'secondaryBackground']
                        : ['lightGrey', 'lightGrey', 'secondaryBackground']
                    }
                    py="1"
                    px="4"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="lg"
                    minH={8}
                    minW={130}
                    display="flex"
                    onClick={() => setActiveTab('equipment')}
                    _hover={{ cursor: 'pointer' }}
                  >
                    <CustomIcon name="cooking" size="md" mr="2" />
                    <Text fontSize="xs" fontWeight="bold" fontFamily="Greed" letterSpacing="0.6px">
                      EQUIPMENT
                    </Text>
                  </Flex>
                </Skeleton>

                {isLoading ? (
                  <Skeleton
                    isLoaded={false}
                    loadingStyle={{ width: '100%', height: '200px', marginTop: '4' }}
                  />
                ) : (
                  <Flex
                    mt="4"
                    display={['none', 'none', 'flex']}
                    backgroundImage={equipmentMin}
                    width="100%"
                    height="200px"
                    backgroundSize="contain"
                    backgroundRepeat="no-repeat"
                  />
                )}
              </Flex>
            </Flex>
            <Flex
              mt="4"
              display={['flex', 'flex', 'none']}
              backgroundImage={activeTab === 'ingredients' ? ingredientsMin : equipmentMin}
              width="100%"
              height="200px"
              backgroundSize="contain"
              backgroundRepeat="no-repeat"
            />
            <Flex
              justifyContent={['center', 'center', 'flex-start']}
              alignItems="center"
              mt="8"
              mb="8"
            >
              <ExternalLink
                href="https://onelink.sorted.club/vWe9/sharerecipe"
                target="_blank"
                _hover={{ textDecoration: 'none' }}
              >
                <Flex
                  backgroundColor="primary"
                  py="1"
                  px="8"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="lg"
                  minH={8}
                  minW={130}
                  display="flex"
                >
                  <CustomIcon name="download" size="md" mr="2" />
                  <Text
                    fontFamily="Greed"
                    fontSize="sm"
                    fontWeight="bold"
                    letterSpacing="0.6px"
                    lineHeight="26px"
                  >
                    DOWNLOAD SIDEKICK
                  </Text>
                </Flex>
              </ExternalLink>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {isLoading ? null : (
        <Box display={['none', 'none', 'block']}>
          <Flex justifyContent="center" alignItems="center">
            <Flex
              width={['100vw', '100vw', '70vw']}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Text
                fontFamily="Greed"
                fontSize="28px"
                fontWeight="bold"
                letterSpacing="0.6px"
                lineHeight="28px"
              >
                Don't take our word for it...
              </Text>
            </Flex>
          </Flex>
          <Carousel
            selectedItem={activeIndex}
            onChange={index => setActiveIndex(index)}
            showThumbs={false}
            infiniteLoop
            centerMode
            centerSlidePercentage={20}
            showIndicators
            renderIndicator={(handleClickIndicator, selected, idx) => (
              <Box
                // @ts-ignore
                onClick={() => handleClickIndicator(idx)}
                style={{
                  cursor: 'pointer',
                  margin: '0 8px',
                  display: 'inline-block',
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  backgroundColor: selected ? '#000' : '#aaa',
                }}
              />
            )}
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    position: 'absolute',
                    zIndex: 2,
                    top: 'calc(50% - 15px)',
                    right: 15,
                    cursor: 'pointer',
                    border: 'none',
                    background: 'none',
                    outline: 'none',
                  }}
                >
                  <CustomIcon name="chevron-right" size="md" />
                </button>
              )
            }
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    position: 'absolute',
                    zIndex: 2,
                    top: 'calc(50% - 15px)',
                    left: 15,
                    cursor: 'pointer',
                    border: 'none',
                    background: 'none',
                    outline: 'none',
                  }}
                >
                  <CustomIcon name="chevron-left" size="md" />
                </button>
              )
            }
            showStatus={false}
          >
            {testimonials.map((img, index) => (
              <Box key={index} minH="250px" height="25vw" p="8">
                <img src={img} width="100%" height="100%" />
              </Box>
            ))}
          </Carousel>
        </Box>
      )}
    </>
  );
};

export default RecipeScreen;
