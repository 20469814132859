import { History } from 'history';
import React from 'react';
import { Redirect, Route as RouterRoute, Router, Switch } from 'react-router-dom';
import { useIsAuthenticated } from 'redux/selectors';
import navRoutes from './Routes';

interface RoutesRendererProps {
  isAuthenticated: boolean;
}

const Route = RouterRoute;

const RoutesRenderer: React.FC<RoutesRendererProps> = ({ isAuthenticated, ...rest }) => {
  return (
    <Switch>
      {Object.keys({ ...navRoutes.public }).map((k, idx) => {
        const route = navRoutes.public[k];
        const { path, component } = route;
        return <Route key={`route-${path()}_${idx}`} path={path()} component={component} />;
      })}
      <Redirect to={`${navRoutes.public.download.path()}`} />
    </Switch>
  );
};

// const signInSuccess = (userInfo: UserInfo): SignInSuccess => ({
//   type: 'SIGN_IN_SUCCESS',
//   payload: { ...userInfo },
// });

const AppRouter: React.FC<{ history: History<unknown> }> = ({ history }) => {
  // const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();

  // const [initAuthCompleted, setInitAuthCompleted] = useState(false);
  // const [authLoadingDone, setAuthLoadingDone] = useState(false);

  // When the application is first loaded, attempt to refresh the JWT token, if one is present
  // useEffect(() => {
  //   const unsubscribe = auth.onAuthStateChanged(async user => {
  //     if (user) {
  //       const token = await getIdTokenResult(user).then(res => res);

  //       // Send the token to the redux store
  //       dispatch(
  //         signInSuccess({
  //           token: token.token,
  //           expirationTime: token.expirationTime,
  //           displayName: user.displayName,
  //         }),
  //       );
  //     }

  //     setInitAuthCompleted(true);
  //   });

  //   return unsubscribe();
  // }, [setInitAuthCompleted, dispatch]);

  // Once the JWT token has been refreshed (or failed to refresh), attempt
  // to retrieve the user's details. This will only run if the refresh token
  // request was successful and the user is marked as authenticated
  // useEffect(() => {
  //   if (!initAuthCompleted || authLoadingDone) return;

  //   if (!isAuthenticated) {
  //     setAuthLoadingDone(true);
  //     return;
  //   }

  //   const loadUserData = async () => {
  //     await dispatch(authActions.retrieveUser());
  //     setAuthLoadingDone(true);
  //   };

  //   loadUserData();
  // }, [initAuthCompleted, isAuthenticated, authLoadingDone, dispatch]);

  // if (!initAuthCompleted || !authLoadingDone) {
  //   return null;
  // }

  return (
    <Router history={history}>
      <RoutesRenderer isAuthenticated={isAuthenticated} />
    </Router>
  );
};

export default AppRouter;
